import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        if (search.fiscal_year) {
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.org_name && (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1)) {
          const rowItem = [
            { text: vm.$t('elearning_config.organization'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.org_name_bn : search.org_name, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_type) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_type_bn : search.training_type, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_category) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_category'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_category_bn : search.training_category, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_title) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_title_bn : search.training_title, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.trainee_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.course'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.total_marks'), style: 'th', alignment: 'right', bold: true },
            { text: vm.$t('elearning_tim.attain_marks'), style: 'th', alignment: 'right', bold: true }
          ]
        ]
        if (data.length) {
          data.forEach((info, index) => {
            info.trainee_info.forEach((traineeInfoItem, traineeIndex) => {
              traineeInfoItem.course_info.forEach((courseItem, courseIndex) => {
                if (traineeIndex === 0 && courseIndex === 0) {
                  allRows.push([
                    { text: vm.$n(index + 1), alignment: 'center', style: 'td', rowSpan: info.row_span },
                    { text: i18n.locale === 'bn' ? info.org_bn : info.org, alignment: 'center', style: 'td', rowSpan: info.row_span },
                    { text: i18n.locale === 'bn' ? traineeInfoItem.personal_info_name_bn : traineeInfoItem.personal_info_name, alignment: 'center', style: 'td', rowSpan: traineeInfoItem.course_info.length },
                    { text: i18n.locale === 'bn' ? courseItem.course_name_bn : courseItem.course_name, alignment: 'center', style: 'td' },
                    { text: vm.$n(courseItem.total_marks), alignment: 'right', style: 'td' },
                    { text: vm.$n(courseItem.attain_marks), alignment: 'right', style: 'td' }
                  ])
                }
                if (traineeIndex && courseIndex === 0) {
                  allRows.push([
                    { },
                    { },
                    { text: i18n.locale === 'bn' ? traineeInfoItem.personal_info_name_bn : traineeInfoItem.personal_info_name, alignment: 'center', style: 'td', rowSpan: traineeInfoItem.course_info.length },
                    { text: i18n.locale === 'bn' ? courseItem.course_name_bn : courseItem.course_name, alignment: 'center', style: 'td' },
                    { text: vm.$n(courseItem.total_marks), alignment: 'right', style: 'td' },
                    { text: vm.$n(courseItem.attain_marks), alignment: 'right', style: 'td' }
                  ])
                }
                if (courseIndex) {
                  allRows.push([
                    { },
                    { },
                    { },
                    { text: i18n.locale === 'bn' ? courseItem.course_name_bn : courseItem.course_name, alignment: 'center', style: 'td' },
                    { text: vm.$n(courseItem.total_marks), alignment: 'right', style: 'td' },
                    { text: vm.$n(courseItem.attain_marks), alignment: 'right', style: 'td' }
                  ])
                }
              })
            })
          })
        } else {
          allRows.push([
            { text: vm.$t('globalTrans.noDataFound'), alignment: 'center', style: 'td', colSpan: 6 },
            { },
            { },
            { },
            { },
            { }
          ])
        }
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        if (i18n.locale === 'en') {
          pdfContent.push({
            text: [
                vm.$t('tpm_report.report_footer1') + ' ',
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        if (i18n.locale === 'bn') {
          pdfContent.push({
            text: [
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer1') + ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        footer: function (currentPage, pageCount) {
          return [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 20,
                  y1: 0,
                  x2: 595 - 20,
                  y2: 0, // Adjust x2 to the page width minus margin
                  lineWidth: 0.5,
                  lineColor: 'black'
                }
              ]
            },
            {
              columns: [
                {
                  text: vm.$t('tpm_report.page') + ' ' + vm.$n(currentPage.toString()) + ' / ' + vm.$n(pageCount),
                  alignment: 'left',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.idsdp'),
                  alignment: 'center',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.footer_date_time') + ' ' + new Date().toLocaleDateString('bn-BD') + ', ' + new Date().toLocaleTimeString('bn-BD', { hour12: false }),
                  alignment: 'right',
                  style: 'footerStyle'
                }
              ],
              margin: [10, 5] // Adjust the margin as needed
            }
          ]
        },
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          footerStyle: {
            fontSize: 8,
            margin: [20, 5, 20, 5]
          },
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
        //   address: {
        //     fontSize: 9,
        //     margin: [0, -10, 0, 0]
        //   },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
